<template>
  <div>
    <div class="text-center">
      <h2 class="text-h4 mb-3">LITERATURE ADMIN</h2>
      <div style="width: 80px; height: 4px" class="mb-3 secondary mx-auto" />
    </div>

    <v-card class="pa-3 mx-auto" max-width="500">
      <v-alert 
        v-if="Object.keys(status).length===0" 
        :value="wrongPasswordAlert" 
        rounded 
        type="warning" 
        color="light"
      >
        Wrong password
      </v-alert>

      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="email" label="Email" outlined></v-text-field>
        <v-text-field
          v-model="password" 
          label="Password" 
          outlined 
          hide-details 
          type="password"
        >
        </v-text-field>
        <!-- {{ rememberMe }}
        <v-checkbox v-model="rememberMe" label="Remember me" color="red" value></v-checkbox> -->
        <v-spacer></v-spacer>
        <v-btn block class="success" x-large @click="login">Sign In</v-btn>
        <div class="text-center mt-2 text-body-2">
          <router-link to="#">Forgot your password?</router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginPage',
  components: {
  },
  data() {
    return {
      name: '',
      email: '',
      username: '',
      password: '',
      confirm: '',
      wrongPasswordAlert: false
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapGetters('account', ['loggedIn'])
  },
  created() {
    setTimeout(() => {
      this.wrongPasswordAlert = false
    },3000)
  },
  mounted() {

  },
  methods: {
    async login() {
      const creds = {
        username: this.email,
        password: this.password
      }

      this.$store.dispatch('account/login', creds).catch((err) => {
        this.wrongPasswordAlert  = true
        console.log(err)
      })

    }
  }
}
</script>